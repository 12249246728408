a {
  text-decoration: none;
  cursor: pointer;
}

button {
  outline: none;
  cursor: pointer;
}

h1, h2 {
  margin: 0;
}

header, footer {
  backdrop-filter: blur(2px);
  margin: auto;
}

header {
  font-variant-ligatures : none;
  width: 100%;
  position: fixed;
}

footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

header h1 {
  text-align: center;
  font-weight: bold;
  letter-spacing: 5px;
  font-size: 1.2rem;
  line-height: 36px;
  color: #FFF;
  background-color: rgba(62, 56, 42, 0.8);
}

footer p {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 36px;
  margin: 0;
  color: #FFF;
  background-color: rgba(62, 56, 42, 0.8);
}

.flex {
  text-align: center;
  display: flex;
	align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
/* home.js */

.flex > a {
  width: 100%;
  height: 90%;
}

.title > h1 {
  margin: 0;
  font-size: 5.5rem;
  font-weight: 500;
}

.tori-block {
  text-align: center;
  display: flex;
  flex-basis: 45%;
  align-items: center;
  justify-content: center;
  height: 96%;
  padding: 2% 5% 2% 10%;
}

.tori-block > div {
  font-size: 1.8rem;
}
.tori-block > div:first-child {
  flex-basis: 40%;
}

.tori-block > div:last-child {
  flex-basis: 60%;
}

.tori-img {
  width: 100%;
}

button {
  background: linear-gradient(100.78deg, #653D97 0%, #417592 52.6%, #634097 102.01%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
  font-family: a-otf-jun-pro,sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 2rem;
  line-height: 40px;
  /* identical to box height */
  text-align: center;
  color: #FFFFFF;
}

button:hover {
  background: linear-gradient(100.78deg, #2ECADF 0%, #DAE98F 52.6%, #39BFDC 102.01%);
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 4px 2px rgba(0, 0, 0, 0.25);
}

button.selected {
  background: linear-gradient(100.78deg, #2ECADF 0%, #DAE98F 52.6%, #39BFDC 102.01%);
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 4px 2px rgba(0, 0, 0, 0.25);
}

.index-link > p {
  font-family: A-OTF Jun Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
}

/* quesions */
.question-block > h1 {
  margin-top: 0;
  font-size: 4rem;
}

.question-buttions > a {
  width: 100%;
}

.question-label {
  font-size: 2.2rem;
  font-weight: bold;
}

.answer-button {
  width: 80%;
  margin: 5px;
}

/* result */
.result-image img {
  padding-top: 10%;
  height: 80%;
}

.result-image p {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.8rem;
}

.result-share > p {
  margin: 0;
  font-size: 1.2rem;
}

.result-description {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding: 0 10px;
  font-weight: bold;
}

.result-copy {
  width: 80%;
  text-align: left;
}

.result-copy > p {
  margin: 0;
  font-size: 1.2rem;
}

textarea:focus {
  outline: none;
}

.result-copy > textarea {
  margin-top: 5px;
  border-radius: 5px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(105, 79, 39, 0.7);
  box-sizing: border-box;
}

.result-tori {
  position: sticky;
  bottom: 0;
  background-image: url(./assets/fukidashi.png);
  background-size: cover;
}

.result-tori > .fukidashi {
  margin-left: 34%;
  padding-top: 7%;
}

.result-tori > .fukidashi > p {
  margin: 3px 0;
}

.share-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
}

.share-button {
  width: 46px;
  height: 46px;
  margin-left:10px;
  margin-right:10px;
  padding: 0;
}

.scene-background {
  background: #694F27;
}

.scene-frame {
  background-image: url(./assets/frame.png);
  background-size: contain;
}
.scene-frame-contents {
  height: 100%;
  width: 73%;
  margin: 0 13% 0 14%;
}

.cinema-result {
  padding: 15% 0;
  height: 64%;
}

.cinema-result > .full-content {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.cinema-result > div > span {
  display: inline-block;
  font-size: 1.3rem;
  margin: 2px 0;
  font-weight: bold;
}