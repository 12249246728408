@import-normalize;

html {
  /*
    remで計算しやすいように
    16px = 1.6rem
  */
  font-size: 10px;
}

@media screen and (max-width: 360px) {
  html {
    font-size: 8px;
  }
}

body {
  margin: 0;
  font-family: a-otf-jun-pro,sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #694F27;
  background: #F3E5C2;
  /*  iOSだけボタン文字がずれて見える問題のための対策 */
  -webkit-text-size-adjust: none;
}

h1 {
  font-family: vdl-gigag,sans-serif;
  font-weight: 500;
  font-style: normal;
}

h1, h2 {
  color: #593E16;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #653D97;
}
a:visited {
  color: #39BFDC;
}


h2 {
  font-size: 2.4rem;
}

p {
  font-size: 1.6rem;
}

.container {
  margin: auto;
  background: #593E16;
  min-height: calc(100vh - 72px);
  /* headerとfooter分 */
  padding: 36px 0;
}